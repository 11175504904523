import React, { Component } from "react";
import styled from "styled-components";
import { getViews, incrementViews } from "api/db";

const CounterText = styled.h4`
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: uppercase;
  display: inline-block;
  float: right;
`;

class ViewCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hits: 0
    };
  }
  componentDidMount() {
    getViews(this.props.id).then(snapshot => {
      let data = snapshot.val();
      this.setState({
        hits: data
      });
    });
    incrementViews(this.props.id);
  }

  render() {
    const { hits } = this.state;
    return <CounterText>{hits && hits.toLocaleString()} views</CounterText>;
  }
}

export default ViewCounter;
