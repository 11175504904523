import React, { Component, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";

import { PostContainer } from "components/Post/styles";
import Header from "components/Post/Header";
import OnboardingArticleComponent from "pages/onboarding/OnboardingArticle";
import {
  Header as OnboardingHeader,
  Next,
  Exit,
} from "components/Onboarding/OnboardingComponents";

class OnboardingProject extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.location.state && typeof window != "undefined") {
      localStorage.setItem(
        "projectTitle",
        this.props.data.tutorial.title
      );
      localStorage.setItem("numericalId", this.props.data.tutorial.projectId);
      localStorage.setItem("projectSlug", this.props.data.tutorial.id);
    }
  }
  // exitClick(e) {
  //   if (process.env.NODE_ENV === "production") {
  //     typeof window !== "undefined" &&
  //       window.gtag("event", "click", {
  //         event_label: "onboarding project",
  //         event_category: "Onboarding Exit",
  //       });
  //   }
  // }

  render() {
    const post = this.props.data.tutorial;

    const Button = styled.button`
      padding: 8px;
      font-size: 24px;
      cursor: pointer;
      background: #fff;
      color: #000;
      border: 4px solid #0394fc;
      margin-right: 8px;
      border-radius: 4px;
      transition: all 300ms ease;
      font-weight: bold;
      &:hover {
        opacity: 0.8;
        background: #0394fc;
        color: #fff;
      }
    `;

    const ButtonContainer = styled.div`
      width: 100%;
      text-align: center;
      margin-bottom: 64px;
    `;

    return (
      <>
        <OnboardingHeader />
        <PostContainer>
          <Helmet
            title={`${post.title} with ${post.language} | Enlight`}
            meta={[
              { name: "description", content: `${post.description}` },
              {
                name: "og:title",
                content: `${post.title} with ${post.language}`,
              },
              {
                name: "og:url",
                content: `https://enlight.nyc/projects/${post.id}`,
              },
              {
                name: "og:description",
                content: `${post.description}`,
              },
              {
                name: "og:image",
                content: `${post.img}`,
              },
              { name: "twitter:card", content: "summary" },
              { name: "twitter:site", content: "@tryenlight" },
              {
                name: "twitter:title",
                content: `${post.title} with ${post.language}`,
              },
              {
                name: "twitter:url",
                content: `https://enlight.nyc/projects/${post.id}`,
              },
              {
                name: "twitter:description",
                content: `${post.description}`,
              },
              {
                name: "twitter:image",
                content: `${post.img}`,
              },
            ]}
          ></Helmet>
          <Link
            style={{ color: "black" }}
            to="/home"
            //onClick={(e) => this.exitClick(e)}
          >
            <Exit id="exit">&#10005;</Exit>
          </Link>
          <Header
            title={post.title}
            description={post.description}
            writer={post.author}
            writerId={post.author_username}
            category={post.category}
            language={post.language}
            difficulty={post.difficulty}
            date={post.date}
            id={post.id}
            img={post.img}
            source={post.sourceURL}
            demourl={post.demoURL}
          />
          <OnboardingArticleComponent
            title={post.title}
            id={post.id}
            language={post.language}
            content={post.rawBody}
            toc={post.toc}
            projectId={post.projectId}
          />
          <ButtonContainer>
            <Next
              paintDrip
              to="/onboarding/project-finished"
              hex="#438cee"
              duration={1}
            >
              Let's ship it &#8250;
            </Next>
          </ButtonContainer>
        </PostContainer>
      </>
    );
  }
}

export default OnboardingProject;

export const onboardingProjectsQuery = graphql`
  query OnboardingProjectsQuery($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    tutorial(id: { eq: $id }) {
      author
      author_uid
      author_username
      category
      date
      demoURL
      description
      difficulty
      id
      img
      language
      rawBody
      sourceURL
      title
      projectId
    }
  }
`;
