import React from "react";

import {
  ArticleWrapper,
  Project,
  Share,
  PostAd,
  Feedback
} from "components/Post/styles";

import Markdown from "react-remarkable-with-plugins";
import hljs from "highlight.js";

import AuthUserContext from "components/Session/AuthUserContext";

const OnboardingArticleComponent = ({
  title,
  id,
  content,
  language,
  toc,
  projectId
}) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <OnboardingArticle
          title={title}
          id={id}
          content={content}
          language={language}
          toc={toc}
          projectId={projectId}
          uid={authUser.uid}
        />
      ) : (
        <OnboardingArticle
          title={title}
          id={id}
          content={content}
          language={language}
          toc={toc}
          projectId={projectId}
          uid={null}
        />
      )
    }
  </AuthUserContext.Consumer>
);

const OnboardingArticle = ({
  title,
  id,
  content,
  language,
  toc,
  projectId,
  uid
}) => {
  const highlight = (str, lang) => {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(lang, str).value;
      } catch (err) {
        console.error(err);
      }
    }

    try {
      return hljs.highlightAuto(str).value;
    } catch (err) {
      console.error(err);
    }

    return "";
  };

  const url = `https://enlight.nyc/projects/${id}`;

  return (
    <>
      <ArticleWrapper>
        <Project>
          <Markdown options={{ highlight, html: true }}>{content}</Markdown>
        </Project>
      </ArticleWrapper>
      <Feedback>
        Any questions? Join the Enlight{" "}
        <a href="https://discord.gg/8FwTpfN"> community</a> to chat with our
        team!
      </Feedback>
    </>
  );
};

export default OnboardingArticleComponent;
